import t1 from '../Assets/adwiti.jpeg';
import t2 from '../Assets/amit.jpeg';
import t3 from '../Assets/madhuri.jpeg';
import t4 from '../Assets/sikandar.jpeg';
import t5 from '../Assets/mintu.jpeg';

export const items = [
    {
        status: "inactive",
        imgSrc: t1,
        name: "Adwiti Kumari",
        position: "Placed at: INDIGO AIRLINES",
        quote: "I am grateful to Talent Managemengt Center - both the faculty and the Training & Placement Department."
    },
    {
        status: "inactive",
        imgSrc: t2,
        name: "Amit Kumar",
        position: "Placed at: RKFL",
        quote: "It was a great experience studying at Talent Managemengt Center, a memory to cherish for lifetime."
    },
    {
        status: "inactive",
        imgSrc: t3,
        name: "Madhuri Kumari",
        position: "Placed at: TELEPERFORMANCE",
        quote: "Talent Management Center and SET faculties have put in all the efforts to groom us and make us corporate professionals."
    },
    {
        status: "active",
        imgSrc: t4,
        name: "Skinder Roy",
        position: "Placed at: NIT Jsr",
        quote: "I have established time management and have also been able to advance these skills to the whole new augment level."
    },
    {
        status: "inactive",
        imgSrc: t5,
        name: "Mintu Paul",
        position: "Placed at: ICICI Bank",
        quote: "The mentors at Talent management Center helped us enhance interpersonal skills."
    }
];

export default items;